import { ref, computed } from 'vue'
import { NdfStatus } from '@/assets/js/Constants.js'
import { useRemoteActivity } from '@/composables/useRemoteActivity'
import { useStore } from '@/stores'
import { LOAD_NDF_CATEGORIES } from '../stores/action-types'
import { NDF_CATEGORY } from '../stores/getter-types'
import { NDF_CATEGORIES } from '../stores/state-keys'

const splitDescription = value => {
  const regex = /^(?<client>CLIENT: (.+)\r?\n)?(?<desc>(.*\r?\n?)*)$/g
  const [[_, __, client, description]] = [...`${value}\n`.matchAll(regex)]

  return { description: description.trim(), client }
}

const { isRemoteDay } = useRemoteActivity()
const isRestaurant = cat => cat?.Code === '625600'

const ndfStatuses = ref(Object.values(NdfStatus))
const formatStatus = (status) => {
  if (status == NdfStatus.Pending) return 'En attente de validation'
  if (status == NdfStatus.Validated) return 'Validée'
  if (status == NdfStatus.Rejected) return 'Rejetée'
  if (status == NdfStatus.Payed) return 'Payée'
}
const getStatusVariant = (status) => {
  if (status == NdfStatus.Pending) return 'secondary'
  if (status == NdfStatus.Validated) return 'success'
  if (status == NdfStatus.Rejected) return 'danger'
  if (status == NdfStatus.Payed) return 'gold'
}

export const useNdf = () => {
  const store = useStore()
  const getCategory = ndf => store[NDF_CATEGORY](ndf.CategoryId)
  const isNdfInConflict = ndf => isRestaurant(getCategory(ndf)) && isRemoteDay(ndf)

  return {
    categories: computed(() => store[NDF_CATEGORIES]),
    loadNdfCategories: store[LOAD_NDF_CATEGORIES],
    splitDescription,
    isNdfInConflict,
    ndfStatuses,
    formatStatus,
    getStatusVariant
  }
}

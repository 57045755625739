import { ref, computed } from 'vue'
import { useDates } from '@/composables/useDates'
import { GetMyRemoteActivity, GetAllRemoteActivity } from '@/services'

const { isSameDay } = useDates()
const remoteActivity = ref([])

const loadMyRemoteActivity = () =>
  GetMyRemoteActivity()
    .then(response => (remoteActivity.value = response.data))
    .catch(err => console.error('There was an error: ', err))

const loadAllRemoteActivity = () =>
  GetAllRemoteActivity()
    .then(response => (remoteActivity.value = response.data))
    .catch(err => console.error('There was an error: ', err))

const remoteActivityPerUpn = computed(
  () =>
    remoteActivity.value?.reduce((map, remote) => {
      map.set(remote.Upn, [...(map.get(remote.Upn) ?? []), remote])

      return map
    }, new Map()) ?? new Map()
)

const isRemoteDay = ({ Date, Upn }) =>
  remoteActivityPerUpn.value.get(Upn)?.some(remote => isSameDay(Date)(remote))

export const useRemoteActivity = () => ({
  remoteActivity,
  loadAllRemoteActivity,
  loadMyRemoteActivity,
  isRemoteDay
})

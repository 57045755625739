<template>
  <div>
    <b-row>
      <b-col cols="4" class="ndf-img">
        <b-img
          v-if="ndfIsImage"
          :src="ndf.Photo"
          class="photo-details"
          @click="openContent(ndf)"
        />
        <b-embed
          v-else
          type="iframe"
          :src="ndf.Photo"
          allowfullscreen
          aspect="1by1"
          class="embedContent"
        ></b-embed>
        <a href="#" @click="openContent(ndf)">
          Ouvrir dans une nouvelle fenêtre
        </a>
      </b-col>
      <b-col cols="8">
        <dl class="row">
          <dt :class="dtClass">Date</dt>
          <dd :class="ddClass">{{ formatDate(ndf.Date) }}</dd>
          <dt :class="dtClass">Catégorie</dt>
          <dd :class="ddClass">
            {{ ndf.CategoryName }}
            <b-badge v-if="displayPhoneBadge" variant="info" class="float-right">{{
              badgeLabel
            }}</b-badge>
            <b-badge v-if="isNdfInConflict(ndf)" variant="warning" class="float-right">Journée télétravaillée</b-badge>
          </dd>
          <template v-if="!!ndf.Guests && !!ndf.Guests.length">
            <dt :class="dtClass">Convives</dt>
            <dd :class="ddClass">
              <p
                v-for="({ Name, Company, JobTitle }, idx) in ndf.Guests"
                :key="idx"
                class="no-wrap"
              >
                {{ Name }}, {{ Company }}, {{ JobTitle }}
              </p>
            </dd>
          </template>
          <dt :class="dtClass">Description</dt>
          <dd :class="ddClass">
            <p
              v-if="description.text"
              class="mb-0"
              style="white-space: pre-wrap;"
            >{{ description.text }}</p>
            <p v-if="description.client" class="mb-0">
              <b-badge variant="info">
                CLIENT : {{ description.client }}
              </b-badge>
            </p>
          </dd>
          <dt :class="dtClass">
            <span class="mr-2">Montants TVA</span>
            <b-badge v-if="ndf.IntraCommunityPurchase" variant="info"
              >Intra communautaire</b-badge
            >
          </dt>
          <dd :class="ddClass">
            <p v-for="(vat, idx) in ndf.VatItems" :key="idx" class="text-right">
              {{ formatVatAmountDetails(vat) }}
            </p>
          </dd>
          <dt :class="dtClass">Total</dt>
          <dd class="col">
            <p class="text-right">HT :</p>
            <p class="text-right">TVA :</p>
            <p class="text-right">TTC :</p>
          </dd>
          <dd v-if="displayPhoneBadge && isPhoneUsageMixed" class="col-2">
            <p class="text-right">
              <del class="text-info">{{ exclAmountRaw }} €</del>
            </p>
            <p class="text-right">
              <del class="text-info">{{ vatAmountRaw }} €</del>
            </p>
            <p class="text-right">
              <del class="text-info">{{ inclAmountRaw }} €</del>
            </p>
          </dd>
          <dd :class="`col-${displayPhoneBadge && isPhoneUsageMixed ? 2 : 4}`">
            <p class="text-right">
              {{ exclAmount }}
              €
            </p>
            <p class="text-right">
              {{ vatAmount }}
              €
            </p>
            <p class="text-right">
              {{ inclAmount }}
              €
            </p>
          </dd>
        </dl>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset="0" offset-lg="2" offset-xl="4" cols="12" lg="10" xl="8">
        <b-input-group>
          <b-form-input
            v-model="ndf.RejectionReason"
            :placeholder="isPaid ? 'Rejet du comptable' : 'Raison du rejet'"
            :state="rejectedWithReason"
            required
          />
          <b-input-group-append>
            <b-button variant="danger" class="ml-2" @click="onRejectNdf(ndf)"
              ><fa-icon icon="ban" /> Rejeter</b-button
            >
          </b-input-group-append>
          <b-input-group-append v-if="!isPaid">
            <b-button variant="success" class="ml-2" @click="onValidateNdf(ndf)"
              ><fa-icon icon="edit" /> Valider</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VatMixin } from '@/mixins'
import { NdfStatus, PhoneUsage } from '@/assets/js/Constants.js'
import { NdfService } from '@/services'
import { useNdf } from '@/composables/useNdf'

export default {
  name: 'NdfRowDetails',
  mixins: [VatMixin],
  props: {
    ndf: { type: Object, required: true }
  },
  data() {
    return {
      dtClass: 'col-12 col-md-4',
      ddClass: 'col-12 col-md-8'
    }
  },
  setup() {
    const { isNdfInConflict, splitDescription } = useNdf()

    return { isNdfInConflict, splitDescription }
  },
  computed: {
    description() {
      const { description: text, client } = this.splitDescription(
        this.ndf.Description
      )

      return { text, client }
    },
    isPaid() {
      return this.ndf.Status == NdfStatus.Payed
    },
    rejected() {
      return this.ndf.Status == NdfStatus.Rejected
    },
    rejectedWithReason() {
      return this.rejected ? !!this.ndf.RejectionReason : undefined
    },
    ndfIsImage() {
      return (
        (this.ndf.Photo && this.ndf.Photo.indexOf('image/') > -1) ||
        (!!this.ndf.DocumentContentType &&
          this.ndf.DocumentContentType.indexOf('image/') > -1)
      )
    },
    displayPhoneBadge() {
      return this.ndf.PhoneUsage !== undefined && this.ndf.PhoneUsage !== null
    },
    badgeLabel() {
      return this.isPhoneUsageMixed
        ? 'MIXTE'
        : this.isPhoneUsageProOnly
        ? 'PRO'
        : ''
    },
    isPhoneUsageMixed() {
      return this.ndf.PhoneUsage == PhoneUsage.Mixed
    },
    isPhoneUsageProOnly() {
      return this.ndf.PhoneUsage == PhoneUsage.ProOnly
    },
    inclAmount() {
      return this.formatAmount(
        this.getInclAmount(this.ndf.VatItems) * this.refundRate
      )
    },
    vatAmount() {
      return this.formatAmount(
        this.getVatAmount(this.ndf.VatItems) * this.refundRate
      )
    },
    exclAmount() {
      return this.formatAmount(
        this.getExclAmount(this.ndf.VatItems) * this.refundRate
      )
    },
    inclAmountRaw() {
      return this.formatAmount(this.getInclAmount(this.ndf.VatItems))
    },
    vatAmountRaw() {
      return this.formatAmount(this.getVatAmount(this.ndf.VatItems))
    },
    exclAmountRaw() {
      return this.formatAmount(this.getExclAmount(this.ndf.VatItems))
    },
    refundRate() {
      return this.displayPhoneBadge && !this.isPhoneUsageProOnly ? 0.5 : 1
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR')
    },
    formatVatAmountDetails(vat) {
      const inclOrExcl = vat.Included ? 'TTC' : 'HT'

      return `${vat.Amount} ${inclOrExcl} (${vat.Rate * 100}%)`
    },
    onRejectNdf(ndf) {
      if (ndf.RejectionReason) {
        NdfService.RejectNdf(ndf.Id, ndf.RejectionReason)
          .then(() => {
            ndf.Status = NdfStatus.Rejected
          })
          .catch(err => {
            this.$emit('error-raised', err)
          })
      }
    },
    onValidateNdf(ndf) {
      NdfService.ValidateNdf(ndf.Id)
        .then(() => {
          ndf.Status = NdfStatus.Validated
        })
        .catch(err => {
          this.$emit('error-raised', err)
        })
    },
    openContent(ndf) {
      var pdfWindow = window.open('')
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" + ndf.Photo + "'></iframe>"
      )
      pdfWindow.document.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-details {
  width: 100%;
  object-fit: contain;
}
img,
svg {
  &.img-ndf {
    width: 160px;
    height: 120px;
  }
}
.ndf-img {
  text-align: center;
}
.embedContent {
  width: 100%;
}
.b-table-details p {
  margin-bottom: 5px;
}

.no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
